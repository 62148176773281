import React, { useEffect, useState } from "react";
import "./SkuForm.scss";
import { useSelector } from "react-redux";
import { toastOptions } from "../../utils/toast";
import { toast } from "react-toastify";
import ApiService from "../../services/apiService";
import environment from "../../environments/environment";

import URLS from "../../urls/Urls";
import { useNavigate } from "react-router-dom";
import {
  FilterOptionSchema,
  NewCompanyAddressPayloadSchema,
} from "../../redux/oms/omsTypes";
import {
  selectBaseProducts,
  selectClientAddresses,
  selectFilterOptions,
} from "../../redux/oms/omsSelectors";
import Filter from "../oms/Options/Filter/Filter";
import { filters } from "../oms/Options/Filter/Filter.data";
import { useDispatch } from "react-redux";
import {
  fetchCompanyAddressStart,
  newCompanyAddressStart,
  removeCompanyAddress,
} from "../../redux/oms/omsActions";
import { AbstractControl } from "react-reactive-form";
import NewAddressForm from "../oms/NewAddressForm/NewAddressForm";

interface FileObject {
  file: File;
  preview: string;
}

interface SkuFormProps {
  closeForm: () => void;
}

const SkuForm: React.FC<SkuFormProps> = ({ closeForm }) => {
  const navigate = useNavigate();
  const [newUserData, setNewUserData] = useState<{
    name?: string;
    type?: string;
    control?: AbstractControl;
  }>();
  const product_lists = useSelector(selectBaseProducts);
  const clients = useSelector((state) =>
    selectFilterOptions(state, filters[1].key),
  );
  const client_addresses = useSelector(selectClientAddresses);

  const dispatch = useDispatch();

  const [skuName, setSkuName] = useState("");
  const [description, setDescription] = useState("");
  const [measurementUnit, setMeasurementUnit] = useState("");
  const [openNewAddressForm, setOpenNewAddressForm] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState<FilterOptionSchema[]>(
    [],
  );
  const [selectedClient, setSelectedClient] = useState<FilterOptionSchema[]>(
    [],
  );
  const [material, setMaterial] = useState("");
  const [productionRemark, setProductionRemark] = useState("");

  const [artworkFiles, setArtworkFiles] = useState<File[]>([]);
  const [artworkDisplayNames, setArtworkDisplayNames] = useState<string[]>([]);
  const [fileList, setFileList] = useState<FileObject[]>([]);
  const [displayNames, setDisplayNames] = useState<string[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<FilterOptionSchema[]>(
    [],
  );

  const [skuTags, setSkuTags] = useState<FilterOptionSchema[] | []>();
  const [filterSkuTags, setFilterSkuTags] = useState<FilterOptionSchema[] | []>(
    [],
  );

  useEffect(() => {
    return () => {
      fileList.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [fileList]);

  const loadClientAddress = (val: FilterOptionSchema[]) => {
    if (val.length > 0) {
      setSelectedClient(val);
      dispatch(fetchCompanyAddressStart(val[0].id));

      // dispatch(fetchCompanyPocStart(val[0].id));
      // setNewUserData({ name: val[0].name, type: "COMPANY" });
    } else {
      setSelectedClient([]);
      dispatch(removeCompanyAddress());
    }
    setSelectedAddress([]);
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    uploadType: string,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = Array.from(event.target.files);
      const displayNames = files.map((file) => file.name);
      const fileList: File[] = [];
      const displayNameList: string[] = [];
      files.forEach((file, index) => {
        fileList.push(file);
        const displayName = displayNames[index]?.trim();
        if (displayName) {
          displayNameList.push(displayName);
        }
      });

      if (uploadType === "artwork") {
        setArtworkFiles((prevState) => [...prevState, ...fileList]);
        setArtworkDisplayNames((prevState) => [
          ...prevState,
          ...displayNameList,
        ]);
      } else {
        const updatedFileList = files.map((file) => {
          return {
            file,
            preview: URL.createObjectURL(file),
          };
        });
        setFileList((prevFileList) => [...prevFileList, ...updatedFileList]);
      }
    }
  };

  const removeFile = (index: number, fileType: string) => {
    if (fileType === "artwork") {
      setArtworkFiles((prevFileList) =>
        prevFileList.filter((_, i) => i !== index),
      );
      setArtworkDisplayNames((prevDisplayNames) =>
        prevDisplayNames.filter((_, i) => i !== index),
      );
    } else {
      setFileList((prevFileList) => prevFileList.filter((_, i) => i !== index));
      setDisplayNames((prevDisplayNames) =>
        prevDisplayNames.filter((_, i) => i !== index),
      );
    }
  };

  const addNewAddress = (payload: NewCompanyAddressPayloadSchema) => {
    dispatch(newCompanyAddressStart(payload));
    setNewUserData((old) => ({
      ...old,
      name: `${payload.city} - ${payload.line}`,
      type: "ADDRESS",
    }));
    setOpenNewAddressForm(false);
  };

  const addAddress = (id: number) => {
    // if (selectedAddress.indexOf(id) > -1) {
    //   setSelectedAddress((val) => val.filter((row) => row !== id));
    // } else {
    //   setSelectedAddress((val) => [...val, id]);
    // }
  };

  const handleCreateSku = async () => {
    if (skuName.length <= 0) {
      toast.error("Add SKU name !", toastOptions);
      return;
    } else if (selectedClient.length <= 0) {
      toast.error("Please Select Client!", toastOptions);
      return;
    } else if (selectedAddress.length <= 0) {
      toast.error("Please Add Address!", toastOptions);
      return;
    } else if (measurementUnit.length <= 0) {
      toast.error("Add measurement unit!", toastOptions);
      return;
    } else if (description.length <= 0) {
      toast.error("Add Description!", toastOptions);
      return;
    } else if (selectedProduct.length <= 0) {
      toast.error("Add Product!", toastOptions);
      return;
    } else if (productionRemark.length <= 0) {
      toast.error("Add Production remark!", toastOptions);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("name", skuName);
      formData.append("description", description);
      formData.append("material", material);
      formData.append("unit", measurementUnit);
      formData.append("production_remarks", productionRemark);
      formData.append("client_id", selectedClient[0].id.toString());
      formData.append("base_product_id", selectedProduct[0].id.toString());

      artworkFiles.map((artwork, index) => {
        formData.append("artwork_files[]", artwork);
        formData.append("artwork_filenames[]", artwork.name);
      });
      fileList.map((file, index) => {
        formData.append("image_files[]", file.file);
        formData.append("image_filenames[]", file.file.name);
      });
      selectedAddress.map((address, index) => {
        formData.append("address_ids[]", address.id.toString());
      });

      const res = await ApiService().client.post(URLS.SKU.CREATE_SKU, formData);
      if (res.status === 200) {
        navigate(`/skus/${res.data.sku.id}`);
      }
    } catch (error) {
      console.error("Error Creating SKU:", error);
    }
  };

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="sku-form p-16 flex-column gap-24 border-box"
    >
      <div className="xetgo-font-tag bolder form-main-heading flex-row align-items-center justify-content-space-between">
        <div className="flex-row align-items-center gap-4">
          {" "}
          <img
            height={15}
            width={16}
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240717142153-list-plus.svg"
            alt="sku-icon"
          />
          <p>Add SKU</p>
        </div>
        <img
          onClick={() => closeForm()}
          height={16}
          width={16}
          className="cursor-pointer"
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240906095019-x.svg"
          alt="close-btn"
        />
      </div>
      <div className="sku-form-main-container flex-column gap-12 xetgo-font-tag flex-1">
        <div className="flex-row gap-12">
          <div className="form-container flex-column gap-8 flex-1">
            <p className="bold">SKU Name</p>
            <input
              onChange={(e) => setSkuName(e.target.value)}
              className={`sku-form-input px-12 py-8 xetgo-font-tag ${
                skuName.length > 0 && "bold sku-form-input-filled"
              }`}
              placeholder="Enter Name"
            />
          </div>
        </div>

        {/* client name  */}

        <div className="form-container flex-column gap-8">
          <p className="bold">Client Name</p>
          <Filter
            option={{ key: "client", name: "Client Name" }}
            optionList={clients}
            multipleSelection={false}
            onSelection={(val, _option) => {
              loadClientAddress(val);
            }}
            mode="showNames"
            showPlaceholder={false}
            borderStyle="solid"
            preSelected={selectedClient}
            addNew={false}
            classname="sku-form-input"

            // onAddNew={() => setOpenNewClientForm(true)}
          />
        </div>

        {/* client address  */}

        <div className="form-container flex-column gap-8">
          <p className="bold"> Address</p>
          <Filter
            option={{
              key: "address",
              name: "Address",
            }}
            optionList={client_addresses}
            multipleSelection={true}
            onSelection={(val, option) => {
              if (val.length > 0) {
                setSelectedAddress(val);
              } else {
                setSelectedAddress([]);
              }
            }}
            mode="showNames"
            showPlaceholder={false}
            borderStyle="solid"
            preSelected={selectedAddress}
            addNew={false}
            classname="sku-form-input"
          />
        </div>

        {/* measurement unit  */}

        <div className="flex-row gap-12">
          <div className="form-container flex-column gap-8 flex-1">
            <p className="bold">SKU Unit</p>
            <input
              onChange={(e) => setMeasurementUnit(e.target.value)}
              className={`sku-form-input px-12 py-8 xetgo-font-tag ${
                measurementUnit.length > 0 && "bold sku-form-input-filled"
              }`}
              placeholder="Enter Name"
            />
          </div>
        </div>

        {/* client message  */}

        <div className="form-container flex-column gap-8">
          <p className="bold">Description</p>
          <input
            onChange={(e) => setDescription(e.target.value)}
            className={`sku-form-input px-12 py-8 xetgo-font-tag ${
              description.length > 0 && "bold sku-form-input-filled"
            }`}
            placeholder="Enter Description"
          />
        </div>

        {/* product select  */}

        <div className="form-container flex-column gap-8">
          <p className="bold">Product</p>
          <Filter
            option={{
              key: "productName",
              name: " Product",
            }}
            optionList={product_lists}
            multipleSelection={false}
            onSelection={(val, _option) => {
              if (val.length > 0) {
                setSelectedProduct(val);
                setMaterial(val[0].material || "");
              } else {
                setMaterial("");
                setSelectedProduct([]);
              }
            }}
            mode="showNames"
            showPlaceholder={false}
            borderStyle="solid"
            showId={true}
            preSelected={selectedProduct}
            direction="left"
            addNew={false}
            classname="sku-form-input"
          />
        </div>

        {/* product  material  */}

        <div className="form-container flex-column gap-8">
          <p className="bold">Material</p>
          <input
            value={material}
            className={`sku-form-input px-12 py-8 xetgo-font-tag ${
              material.length > 0 && "bold sku-form-input-filled"
            }`}
            placeholder="Enter Material"
            disabled
          />
        </div>

        {/* production remark  */}

        <div className="flex-row gap-12">
          <div className="form-container flex-column gap-8 flex-1">
            <p className="bold">Production Remarks:</p>
            <input
              onChange={(e) => setProductionRemark(e.target.value)}
              className={`sku-form-input px-12 py-8 xetgo-font-tag ${
                productionRemark.length > 0 && "bold sku-form-input-filled"
              }`}
              placeholder="Remarks..."
            />
          </div>
        </div>

        {/* artwork /  */}

        <div className="form-container flex-column gap-8">
          <p>Artwork</p>
          <div>
            <input
              onChange={(event) => {
                handleFileChange(event, "artwork");
              }}
              id="sku-raise-fileInput"
              name="file"
              type="File"
              style={{
                position: "absolute",
                top: "12px",
                right: "12px",
                display: "none",
              }}
              multiple
            />
            <label htmlFor="sku-raise-fileInput">
              <p className="sku-form-input xetgo-font-tag px-12 py-8 flex-row align-items-center cursor-pointer justify-content-space-between">
                Upload Artwork
                <img
                  height={16}
                  width={16}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240714075812-upload+%286%29.svg"
                  alt="upload-icon"
                />
              </p>
            </label>
          </div>
          {artworkFiles.length > 0 && (
            <div className="flex-row gap-8 all-attachment-container">
              {artworkFiles.map((file, index) => {
                return (
                  <div
                    key={index}
                    className="sku-raise-attachment-pill px-8 py-4 flex-row gap-10 align-items-center"
                  >
                    <p>{artworkDisplayNames[index]}</p>
                    <img
                      height={12}
                      width={12}
                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240507184147-x.svg"
                      alt="remove-icon"
                      className="cursor-pointer"
                      onClick={() => removeFile(index, "artwork")}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>

        {/* sku images  */}

        <div className="form-container flex-column gap-8">
          <p>Add SKU Images</p>
          <div>
            <input
              onChange={(event) => {
                handleFileChange(event, "skuImages");
              }}
              id="sku-images"
              name="file"
              type="File"
              style={{
                position: "absolute",
                top: "12px",
                right: "12px",
                display: "none",
              }}
              multiple
            />
            <label htmlFor="sku-images">
              <p className="sku-form-input xetgo-font-tag px-12 py-8 flex-row align-items-center cursor-pointer justify-content-space-between">
                Upload Image
                <img
                  height={16}
                  width={16}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240714075812-upload+%286%29.svg"
                  alt="upload-icon"
                />
              </p>
            </label>
          </div>

          {fileList.length > 0 && (
            <div className="all-attachment-container flex-row gap-8">
              {fileList.map((file, index) => (
                <div
                  key={index}
                  className="sku-image-container position-relative"
                >
                  <img
                    className="sku-detail-image"
                    height={61}
                    width={61}
                    src={file.preview}
                    alt="images"
                  />
                  <img
                    onClick={() => removeFile(index, "skuImages")}
                    className="remove-sku-image cursor-pointer"
                    height={12}
                    width={12}
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240715055536-Frame+1321314633.svg"
                    alt="remove-icon"
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div
        onClick={handleCreateSku}
        className="create-sku-btn xetgo-font-tag bold py-6 px-12 flex-row align-items-center justify-content-center gap-10 border-box cursor-pointer"
      >
        <img
          height={10}
          width={10}
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240717130726-Frame+1321314714.svg"
          alt="sku-icon"
        />
        <p>Create SKU</p>
      </div>
      {openNewAddressForm && selectedClient.length > 0 && (
        <div className="new-user-form-container position-relative">
          <NewAddressForm
            type="CLIENT"
            onCancel={() => setOpenNewAddressForm(false)}
            user_id={selectedClient[0].id}
            onAddAddress={addNewAddress}
          />
        </div>
      )}
    </div>
  );
};
export default SkuForm;
