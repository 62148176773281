import React, { RefObject, useEffect, useRef, useState } from "react";
import "./SkuDetailCard.scss";
import { ClientPocDetail, SkuDetailSchema } from "../../models/SkuListSchema";
import ApiService from "../../services/apiService";
import URLS from "../../urls/Urls";

import AddPocPopup from "../AddPocPopup/AddPocPopup";
import DeliveryLocationSelector from "../DeliveryLocationSelector/DeliveryLocationSelector";
import EditAddressAddPopup from "../EditAddressAddPopup/EditAddressAddPopup";

interface SkuDetailProps {
  sku: SkuDetailSchema;
  handleUpdate: (data: SkuDetailSchema) => void;
}

const SkuDetailCard: React.FC<SkuDetailProps> = ({ sku, handleUpdate }) => {
  const [skuDetail, setSkuDetail] = useState(sku);
  const [skuName, setSkuName] = useState(sku.name);
  const [description, setDescription] = useState(sku.description);
  const [productionRemark, setProductionRemark] = useState(
    sku.production_remarks,
  );
  const [selectedAddress, setSelectedAddress] = useState<number[]>([]);
  const skuNameRef = useRef<HTMLParagraphElement>(null);
  const [addLocation, setAddLocation] = useState(false);
  const [addPoc, setAddPoc] = useState(false);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const productionRemarkRef = useRef<HTMLDivElement>(null);

  const [editingSku, setEditingSku] = useState(false);
  const [editingProductionRemark, setEditingProductionRemark] = useState(false);

  const [editingPocIndex, setEditingPocIndex] = useState(-1);
  const [editLocationIndex, setEditLocationIndex] = useState(-1);

  const addresses = skuDetail.client_addresses.filter(
    (clientAddress) =>
      !skuDetail.sku_locations.some(
        (skuAddress) => skuAddress.address_id === clientAddress.id,
      ),
  );

  useEffect(() => {
    if (editingSku && skuNameRef.current) {
      (skuNameRef.current as HTMLParagraphElement).focus();
      setCaretToEnd(skuNameRef.current);
    }
  }, [editingSku]);

  useEffect(() => {
    if (editingProductionRemark && productionRemarkRef.current) {
      (productionRemarkRef.current as HTMLParagraphElement).focus();
      setCaretToEnd(productionRemarkRef.current);
    }
  }, [editingProductionRemark]);

  const addAddress = (id: number) => {
    if (selectedAddress.indexOf(id) > -1) {
      setSelectedAddress((val) => val.filter((row) => row !== id));
    } else {
      setSelectedAddress((val) => [...val, id]);
    }
  };

  const handleBlur = (
    ref: RefObject<HTMLElement>,
    setter: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    setter(ref.current?.innerText || "");
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    uploadType: string,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const formData = new FormData();

      const files = Array.from(event.target.files);
      if (uploadType === "ARTWORK") {
        formData.append("attachment_type", "ARTWORK");

        files.forEach((file) => {
          formData.append("files[]", file);
          formData.append("file_names[]", file.name);
        });
      } else {
        formData.append("attachment_type", "IMAGE");

        files.forEach((file) => {
          formData.append("files[]", file);
          formData.append("file_names[]", file.name);
        });
      }
      const { data } = await ApiService().client.put(
        URLS.SKU.UPDATE_SKU_DETAILS(skuDetail.id),
        formData,
      );
      setSkuDetail(data.data);
      handleUpdate(data.data);
    }
  };

  const handleRemoveFile = async (attachment_id: number) => {
    const { data } = await ApiService().client.put(
      URLS.SKU.UPDATE_SKU_DETAILS(skuDetail.id),
      {
        attachment_id: attachment_id,
      },
    );
    setEditingSku(false);
    setSkuDetail(data.data);
    handleUpdate(data.data);
  };

  const handleDeleteLocation = async (addressId: number) => {
    try {
      const { data } = await ApiService().client.delete(
        URLS.SKU.DELETE_SKU_DETAILS(skuDetail.id),
        {
          params: {
            location_id: addressId,
          },
        },
      );
      setEditingSku(false);
      setSkuDetail(data.data);
      handleUpdate(data.data);
    } catch (error) {
      console.error("error deleting location", error);
    }
  };

  const handleUpdateSku = async (updateType: string) => {
    try {
      const formData = new FormData();
      formData.append("sku_id", sku.id.toString());
      if (updateType === "name") {
        const description =
          descriptionRef.current?.innerHTML
            .replace(/&nbsp;/g, " ")
            .replace(/\u00A0/g, " ")
            .replace(/\s+/g, " ")
            .trim() || "";
        const skuName =
          skuNameRef.current?.innerHTML.replace(/&nbsp;| +/g, " ").trim() || "";
        if (skuDetail.name == skuName && skuDetail.description == description) {
          setEditingSku(false);
          return;
        }
        if (skuDetail.name === skuName) {
          formData.append("description", description);
        } else if (skuDetail.description === description) {
          formData.append("name", skuName);
        } else {
          formData.append("description", description);
          formData.append("name", skuName);
        }
      } else if (updateType === "production_remark") {
        const prodRemark =
          productionRemarkRef.current?.innerHTML
            .replace(/&nbsp;/g, " ")
            .replace(/\u00A0/g, " ")
            .replace(/\s+/g, " ")
            .trim() || "";

        if (skuDetail.production_remarks == prodRemark) {
          setEditingProductionRemark(false);
          return;
        } else {
          formData.append("production_remarks", prodRemark);
        }
      } else if (updateType === "address") {
        selectedAddress.map((address: number) => {
          formData.append("address_ids[]", address.toString());
        });
      }

      const { data } = await ApiService().client.put(
        URLS.SKU.UPDATE_SKU_DETAILS(skuDetail.id),
        formData,
      );
      if (updateType === "name") {
        setEditingSku(false);
      } else {
        setEditingProductionRemark(false);
      }

      setSkuDetail((prevState) => {
        if (!prevState) {
          return data;
        }

        return {
          ...prevState,
          name: data.name || prevState.name,
          description: data.description || prevState.description,
          production_remarks:
            data.production_remarks || prevState.production_remarks,
        };
      });
      handleUpdate(data.data);
    } catch (error) {
      console.error("error Updating Sku Detail", error);
    }
  };

  const addLocationHandler = async () => {
    try {
      if (selectedAddress.length === 0) {
        setAddLocation(false);
        setEditingSku(false);
        return;
      }
      const { data } = await ApiService().client.put(
        URLS.SKU.UPDATE_SKU_DETAILS(skuDetail.id),
        {
          sku_id: skuDetail.id,
          new_locations: selectedAddress,
        },
      );
      setEditingSku(false);
      setSkuDetail(data.data);
      handleUpdate(data.data);
      setAddLocation(false);
      setSelectedAddress([]);
    } catch (error) {
      console.error("error adding address", error);
    }
  };

  const setCaretToEnd = (element: HTMLElement) => {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(element);
    range.collapse(false);
    selection?.removeAllRanges();
    selection?.addRange(range);
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLParagraphElement>,
    ref: RefObject<HTMLParagraphElement>,
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
    if (
      event.key === "Backspace" &&
      ref.current?.innerText === "" &&
      skuNameRef.current
    ) {
      (skuNameRef.current as HTMLParagraphElement).focus();
      setCaretToEnd(skuNameRef.current);
      event.preventDefault();
    }
  };

  const handleProductRemarkKeyDown = (
    event: React.KeyboardEvent<HTMLParagraphElement>,
    ref: RefObject<HTMLParagraphElement>,
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
    if (
      event.key === "Backspace" &&
      ref.current?.innerText === "" &&
      productionRemarkRef.current
    ) {
      (productionRemarkRef.current as HTMLParagraphElement).focus();
      setCaretToEnd(productionRemarkRef.current);
      event.preventDefault();
    }
  };

  const handlePocsUpdate = async (poc_details: ClientPocDetail) => {
    try {
      const { data } = await ApiService().client.put(
        URLS.SKU.UPDATE_SKU_DETAILS(skuDetail.id),
        {
          client_id: skuDetail.client_id,
          client_poc: poc_details,
        },
      );

      const newPocs = data.data.client_poc_details;

      setSkuDetail((prevState) => {
        const updatedPocDetails = [...prevState.client_poc_details];

        newPocs.map((newPoc: ClientPocDetail) => {
          const index = updatedPocDetails.findIndex(
            (poc) => poc.id === newPoc.id,
          );
          if (index !== -1) {
            updatedPocDetails[index] = newPoc;
          } else {
            updatedPocDetails.push(newPoc);
          }
        });

        return {
          ...prevState,
          client_poc_details: updatedPocDetails,
        };
      });

      setSkuDetail(data.data);
      setAddPoc(false);
      setEditingPocIndex(-1);
    } catch (error) {
      console.error("Error updating pocs", error);
    }
  };

  const handleNewPocsAdd = async (poc_details: ClientPocDetail[]) => {
    try {
      const { data } = await ApiService().client.put(
        URLS.SKU.UPDATE_SKU_DETAILS(skuDetail.id),
        {
          client_id: skuDetail.client_id,
          new_client_pocs: poc_details,
        },
      );
      setSkuDetail(data.data);
      setAddPoc(false);
      setEditingPocIndex(-1);
    } catch (error) {
      console.error("Error Adding pocs", error);
    }
  };

  const handleAddressSave = (data: SkuDetailSchema) => {
    setSkuDetail(data);
    handleUpdate(data);
  };

  return (
    <div className="sku-detail-card-main-container">
      {/* name and description  */}

      <div className="name-description-container xetgo-font-tag">
        <div className="flex-row justify-content-space-between p-12 xetgo-font-tag">
          <p
            ref={skuNameRef}
            contentEditable={editingSku}
            suppressContentEditableWarning={true}
            onBlur={() => handleBlur(skuNameRef, setSkuName)}
            className="sku-name xetgo-font-caption bolder"
            onKeyDown={(e) => handleKeyDown(e, skuNameRef)}
          >
            {skuName}
          </p>
          <div className="flex-row align-items-center gap-14">
            <p className="uuid px-6 py-4 bold">{skuDetail?.uuid}</p>
            <p className="type flex-row align-items-center gap px-8 py-4">
              {" "}
              Type:
              <span className="material bold ">{skuDetail.material}</span>
            </p>
          </div>
        </div>
        <div className="flex-row p-12 justify-content-space-between gap-10">
          <div
            ref={descriptionRef}
            contentEditable={editingSku}
            suppressContentEditableWarning={true}
            onBlur={() => handleBlur(descriptionRef, setDescription)}
            className="description"
          >
            {description}
          </div>
          {!editingSku ? (
            <img
              onClick={() => setEditingSku(true)}
              className="cursor-pointer"
              height={16}
              width={16}
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240209105532-pen-square.svg"
              alt="edit-icon"
            />
          ) : (
            <div
              onClick={() => handleUpdateSku("name")}
              className="save xetgo-font-tag bold flex-row align-items-end cursor-pointer"
            >
              Save
            </div>
          )}
        </div>
      </div>

      {/* production remarks  */}

      <div className="production-remark-container flex-row align-items-center xetgo-font-tag p-10">
        <div className="flex-column justify-content-space-between gap-10 flex-1">
          <p>Production Remarks</p>
          <p
            ref={productionRemarkRef}
            contentEditable={editingProductionRemark}
            suppressContentEditableWarning={true}
            onBlur={() => handleBlur(productionRemarkRef, setProductionRemark)}
            className="product-remark xetgo-font-tag "
            onKeyDown={(e) =>
              handleProductRemarkKeyDown(e, productionRemarkRef)
            }
          >
            {productionRemark}
          </p>
        </div>
        {!editingProductionRemark ? (
          <img
            onClick={() => setEditingProductionRemark(true)}
            className="cursor-pointer"
            height={16}
            width={16}
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240209105532-pen-square.svg"
            alt="edit-icon"
          />
        ) : (
          <div
            onClick={() => handleUpdateSku("production_remark")}
            className="save xetgo-font-tag bold flex-row align-items-end cursor-pointer"
          >
            Save
          </div>
        )}
      </div>

      {/* clien tdetail unit material  */}

      <div className="client-detail-main-container px-10 full-width border-box">
        <div className="client-detail-container flex-row align-items-center justify-content-space-between">
          <div className="flex-column gap-6 xetgo-font-tag">
            <p className="title">Client Name:</p>
            <p className="sub-title">{skuDetail.client_name}</p>
          </div>
          <div className="flex-column gap-6 xetgo-font-tag">
            <p className="title">SKU Unit:</p>
            <p className="sub-title">{skuDetail.unit}</p>
          </div>
          <div className="flex-column gap-6 xetgo-font-tag">
            <p className="title">Material:</p>
            <p className="sub-title">{skuDetail.material}</p>
          </div>
        </div>
      </div>

      {/* {poc add nd poc detail } */}

      <div className="poc-detail-main-container p-12 flex-column gap-12">
        <div className=" flex-row justify-content-space-between align-items-center xetgo-font-tag">
          <p className="title">POC Details:</p>
          <div
            className="add-btn flex-row align-items-center justify-content-center py-4 px-10 gap-4 cursor-pointer "
            onClick={() => setAddPoc(true)}
          >
            <img
              height={12}
              width={12}
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231026080040-purple_add.svg"
              alt="add-icon"
            />
            <p>Add Poc</p>
          </div>
        </div>
        {skuDetail.client_poc_details &&
          skuDetail.client_poc_details?.length > 0 && (
            <div className="all-poc-main-container flex-column gap-12">
              {skuDetail.client_poc_details.map((poc, pocIndex) => (
                <div
                  key={pocIndex}
                  className="poc-container flex-row xetgo-font-tag"
                >
                  <div className="poc-detail flex-column gap-6 flex-1">
                    <p className="title">Name:</p>
                    <p>{poc.name}</p>
                  </div>
                  <div className="poc-detail flex-column gap-6 flex-1">
                    <p className="title">Email:</p>
                    <p>{poc.email}</p>
                  </div>
                  <div className="poc-detail flex-column gap-6 flex-1">
                    <p className="title">Designation:</p>
                    <p>{poc.designation}</p>
                  </div>
                  <div className="poc-detail flex-column gap-6 flex-1">
                    <p className="title">Number:</p>
                    <p>{poc.phone}</p>
                  </div>
                  <div className="flex-row align-items-end justify-content-end ">
                    <img
                      onClick={() => setEditingPocIndex(pocIndex)}
                      className="cursor-pointer"
                      height={16}
                      width={16}
                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240209105532-pen-square.svg"
                      alt="edit-icon"
                    />
                  </div>
                  {editingPocIndex === pocIndex && (
                    <AddPocPopup
                      handleClose={() => setEditingPocIndex(-1)}
                      clientId={skuDetail.client_id}
                      handlePocsUpdate={handlePocsUpdate}
                      handleNewPocsAdd={handleNewPocsAdd}
                      update={true}
                      pocDetails={poc}
                    />
                  )}
                </div>
              ))}
            </div>
          )}

        {addPoc && (
          <AddPocPopup
            handleClose={() => setAddPoc(false)}
            clientId={skuDetail.client_id}
            handleNewPocsAdd={handleNewPocsAdd}
            handlePocsUpdate={handlePocsUpdate}
          />
        )}
      </div>

      {/* locations  */}

      <div className="location-main-container  flex-column gap-12 xetgo-font-tag p-12">
        <div
          className="flex-row align-items-center justify-content-space-between"
          style={{ height: "26px" }}
        >
          <p> Location: </p>
          {!addLocation &&
            skuDetail.sku_locations &&
            skuDetail.sku_locations?.length > 0 && (
              <div
                className="add-btn flex-row align-items-center justify-content-center py-4 px-10 gap-4 cursor-pointer"
                onClick={() => setAddLocation(true)}
              >
                <img
                  height={12}
                  width={12}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231026080040-purple_add.svg"
                  alt="add-icon"
                />
                <p>Add Location</p>
              </div>
            )}
        </div>

        {addLocation && addresses.length > 0 && (
          <div className="flex-row align-items-center gap-18 full-width">
            <div className="flex-1">
              <DeliveryLocationSelector
                addAddress={addAddress}
                selectedAddress={selectedAddress}
                addresses={addresses}
              />
            </div>
            <p
              className="xetgo-font-tag bold cursor-pointer"
              style={{ color: "#5151EC" }}
              onClick={addLocationHandler}
            >
              Save
            </p>
          </div>
        )}

        {skuDetail &&
          skuDetail.sku_locations &&
          skuDetail.sku_locations.length > 0 && (
            <div className="all-location-container flex-row gap-8">
              {skuDetail.sku_locations.map((location, index) => (
                <div key={index}>
                  <div key={index} className="location-card xetgo-font-tag">
                    <p className="city bolder p-8 flex-row align-items-center justify-content-space-between">
                      {location.city}
                      <div className="flex-row gap-10 align-items-center action-main-container">
                        <img
                          onClick={() => setEditLocationIndex(index)}
                          className="cursor-pointer"
                          height={12}
                          width={12}
                          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240209105532-pen-square.svg"
                          alt="edit-icon"
                        />
                        <img
                          onClick={() => handleDeleteLocation(location.id)}
                          className="cursor-pointer"
                          height={12}
                          width={12}
                          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240308111001-trash-2.svg"
                          alt="delete-icon"
                        />
                      </div>
                    </p>
                    <div className="p-8 flex-column gap-34 flex-1 justify-content-space-between">
                      <p className="line-pin-code flex-column gap-4">
                        {location.address_line} <span>{location.pincode}</span>
                      </p>
                      <div className="flex-row align-items-center justify-content-space-between">
                        <div className=" flex-column gap-6 flex-1">
                          <p className="title">MOQ:</p>
                          <p style={{ color: "black" }}>
                            {location.quantity} {skuDetail.unit}
                          </p>
                        </div>
                        <div className=" flex-column gap-6 flex-1">
                          <p className="title">Lead Time:</p>
                          <p
                            className="flex-row gap-4"
                            style={{ color: "black" }}
                          >
                            <span>{location.lead_time}</span>
                            <span>
                              {`${location.lead_time > 1 ? "days" : "day"} `}
                            </span>
                          </p>
                        </div>
                        <div className=" flex-column gap-6 flex-1">
                          <p className="title">Price:</p>
                          <p style={{ color: "black" }}>
                            ₹{location.selling_price}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {editLocationIndex === index && (
                    <EditAddressAddPopup
                      clientId={skuDetail.client_id}
                      addressDetail={location}
                      handleClose={() => setEditLocationIndex(-1)}
                      handleAddressSave={handleAddressSave}
                      skuId={skuDetail.id}
                    />
                  )}
                </div>
              ))}
            </div>
          )}
      </div>

      {/* artwork  */}

      <div className="image-main-container flex-column gap-12 xetgo-font-tag p-12">
        <div className="flex-row align-items-center justify-content-space-between">
          <p>Artwork:</p>

          <div className="add-btn py-4 px-10 flex-row align-items-center gap-4 cursor-pointer">
            <input
              onChange={(event) => {
                handleFileChange(event, "ARTWORK");
              }}
              id="add-images-artwork"
              name="file"
              type="File"
              style={{
                position: "absolute",
                top: "12px",
                right: "12px",
                display: "none",
              }}
              multiple
            />
            <label htmlFor="add-images-artwork" className="cursor-pointer">
              <p className="flex-row gap-4 align-items-center">
                <img
                  height={12}
                  width={12}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231026080040-purple_add.svg"
                  alt="add-icon"
                />
                Add Image
              </p>
            </label>
          </div>
        </div>

        <div className="all-images-container flex-row gap-8">
          {skuDetail &&
            skuDetail.sku_attachments &&
            skuDetail.sku_attachments?.length > 0 &&
            skuDetail.sku_attachments.map((attachment, index) => {
              if (attachment.attachment_type === "ARTWORK") {
                return (
                  <div
                    key={index}
                    className="artwork-pill-container flex-row gap-4 align-items-center justify-content-center px-8 py-4"
                  >
                    <a
                      href={attachment.file_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="pill-text"
                    >
                      {attachment.file_name}
                    </a>
                    <img
                      onClick={() => handleRemoveFile(attachment.id)}
                      className="remove-sku-image cursor-pointer"
                      height={12}
                      width={12}
                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240507184147-x.svg"
                      alt="remove-icon"
                    />
                  </div>
                );
              }
            })}
        </div>
      </div>

      {/* sku images  */}

      <div className="image-main-container flex-column gap-12 xetgo-font-tag p-12">
        <div className="flex-row align-items-center justify-content-space-between">
          <p>Images:</p>

          <div className="add-btn py-4 px-10 flex-row align-items-center gap-4 cursor-pointer">
            <input
              onChange={(event) => {
                handleFileChange(event, "IMAGE");
              }}
              id="add-images"
              name="file"
              type="File"
              style={{
                position: "absolute",
                top: "12px",
                right: "12px",
                display: "none",
              }}
              multiple
            />
            <label htmlFor="add-images" className="cursor-pointer">
              <p className="flex-row gap-4 align-items-center">
                <img
                  height={12}
                  width={12}
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231026080040-purple_add.svg"
                  alt="add-icon"
                />
                Add Image
              </p>
            </label>
          </div>
        </div>
        <div className="all-images-container flex-row gap-8">
          {skuDetail &&
            skuDetail.sku_attachments &&
            skuDetail.sku_attachments?.length > 0 &&
            skuDetail.sku_attachments.map((attachment, index) => {
              if (attachment.attachment_type === "IMAGE") {
                return (
                  <div
                    key={index}
                    className="sku-image-container position-relative"
                  >
                    <a
                      href={attachment.file_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="sku-detail-image cursor-pointer"
                        height={61}
                        width={61}
                        src={attachment.file_url}
                        alt="images"
                      />
                    </a>
                    <img
                      onClick={() => handleRemoveFile(attachment.id)}
                      className="remove-sku-image cursor-pointer"
                      height={12}
                      width={12}
                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240715055536-Frame+1321314633.svg"
                      alt="remove-icon"
                    />
                  </div>
                );
              }
            })}
        </div>
      </div>
    </div>
  );
};

export default SkuDetailCard;
