import { POCSchema } from "../redux/oms/omsTypes";

export interface TaskDetail {
  id: number;
  task_id: number;
  client_type: string;
  description: string;
  deal_value: string;
  status: string;
  title: string;
  priority: string;
  client_detail: ClientDetailSchema;
  product_details: TaskProductDetails[];
  vendor_detail: {
    id: number;
    name: string;
  };
  assignees: { id: number; name: string; avatar: string; role: string }[];
  pocs: POCSchema[];
  due_date_formatted: string;
  start_date_formatted: string;
  comments: TaskCommentSchema[];
  assets: TaskFileSchema[];
  creator_name: string;
  statuses: string[];
  status_due: boolean;
  task_due: boolean;
  status_overdue: boolean;
  task_overdue: boolean;
  escalated: boolean | null;
  escalated_by_user_id: number | null;
  escalated_id: number | null;
}

export interface ClientDetailSchema {
  id: number;
  name: string;
  avatar: string;
  client_type: string;
  client_poc_name: string;
  client_poc_email: string;
  client_poc_designation: string;
  client_poc_phone: string;
}

export interface TaskProductDetails {
  address_id: number;
  address_line: string;
  address_city: string;
  address_state: string;
  address_country: string;
  address_pincode: string;
  products: ProductDetailSchema[];
}

export interface ProductDetailSchema {
  name: string;
  material: string;
  quantity: string;
  unit: string;
  description: string;
  final_price?: number;
  margin?: number;
  vendor_target_price?: number;
  client_target_price?: number;
  tpm_id: number;
  product_id?: number;
  product_accepted?: boolean;
  vendor_details?: ProductVendorDetails[];
  sample_details?: string[];
  coa_details?: CoaDetails;
  cost_price?: number;
  selling_price?: number;
  delivery_schedules?: {
    id: number;
    prtpm_id: number;
    delivered_quantity: number;
    expected_delivery_date: string;
    actual_delivery_date: string;
  }[];
  notes?: string;
}

export interface CoaDetails {
  id: number;
  name: string;
  url: string;
}

export interface ProductVendorDetails {
  id: number;
  name: string;
  price: number;
  tpm_id: number;
  vendor_id: number;
  approved: boolean;
}

export interface TaskCard {
  category_id: number;
  escalated: boolean | null;
  escalated_by_user_id: number | null;
  escalated_id: number | null;
  task_id: number;
  title: string;
  description: string;
  deal_value: number;
  start_date: string;
  due_date: string;
  priority?: string;
  status: string;
  client: {
    client_name: string;
    client_avatar: string;
    client_type: string;
  };
  assignees: {
    name: string;
    avatar: string;
    role: string;
  }[];
  status_due: boolean;
  status_due_date: string;
  task_due: boolean;
  vendors: {
    name: string;
    id: number;
    po_number: string;
  }[];
  po_number?: string;
}

export interface TaskFileSchema {
  id: number;
  name: string;
  url: string;
  category?: string;
}

export interface TaskCommentSchema {
  name: string;
  avatar: string;
  description: string;
  comment_type: string;
  timestamp: string;
  files?: {
    name: string;
    url: string;
    asset_record_id: number;
    task_comment_id: number;
  }[];
}

export interface PurchaseOrderDetail {
  id: number;
  task_id: number;
  client_type: string;
  description: string;
  status: string;
  title: string;
  priority: string;
  client_detail: {
    id: number;
    name: string;
    avatar: string;
    client_type: string;
  };
  purchase_request_id: number;
  po_number: number;
  po_value: number;
  delivery_address: {
    address_line: string;
    city: string;
    country: string;
    map_link: string;
  };
  product_details: PurchaseProductDetails[];
  vendor_detail: {
    id: number;
    name: string;
  };
  assignees: { id: number; name: string; avatar: string; role: string }[];
  pocs: POCSchema[];
  due_date_formatted: string;
  start_date_formatted: string;
  comments: TaskCommentSchema[];
  assets: TaskFileSchema[];
  creator_name: string;
  statuses: string[];
  status_due: boolean;
  task_due: boolean;
  status_overdue: boolean;
  task_overdue: boolean;
  ordered_products: OrderedProducts[];
}

export interface PurchaseProductDetails {
  vendor_po_number: number;
  vendor_id: number;
  purchase_request_id: number;
  products: PurchaseProduct[];
}

export interface PurchaseProduct {
  cost_price: number;
  prtpm_id: number;
  description: string;
  name: string;
  delivery_schedules: DeliverySchedule[];
  material: string;
  product_id: number;
  quantity: number;
  selling_price: number;
  sku_name: string;
}

export interface OrderedProducts {
  product_name: string;
  cost_price: number;
  selling_price: number;
  description: string;
  name: string;
}

export interface DeliverySchedule {
  id: number;
  prtpm_id: number;
  delivered_quantity: number;
  expected_delivery_date: string;
  actual_delivery_date: string;
  track_link: string;
  pod?: { name: string; url: string };
}

export const TaskStatusColorSchema: {
  [key: string]: { background: string; color: string };
} = {
  "Enquiry Received": {
    background: "#FF6262",
    color: "#F6F6F6",
  },
  "RFQ Generated": {
    background: "#FF6262",
    color: "#F6F6F6",
  },
  "RFQ Review": {
    background: "#FF8359",
    color: "#FFFFFF",
  },
  "RFQ Incomplete": {
    background: "#FFAC82",
    color: "#373737",
  },
  "Sourcing Rejected": {
    background: "#FFA4A4",
    color: "#202020",
  },
  "RFQ Submitted": {
    background: "#FFCB98",
    color: "#484848",
  },
  "Offer Generated": {
    background: "#FFDDB7",
    color: "#484848",
  },
  "Offer Submitted": {
    background: "#FFE6CE",
    color: "#484848",
  },
  "Re-Quote": {
    background: "#FFF0D9",
    color: "#484848",
  },
  "Re-Quoted": {
    background: "#FFF0D9",
    color: "#484848",
  },
  "Discussion & Negotiation": {
    background: "#FFF8C3",
    color: "#484848",
  },
  Samples: {
    background: "#E6FFAA",
    color: "#484848",
  },
  "Re-Sample": {
    background: "#E6FFAA",
    color: "#484848",
  },
  "Sample Decision": {
    background: "#BCFB6C",
    color: "#484848",
  },
  "RFQ Won": {
    background: "#70E77C",
    color: "#101010",
  },
  "RFQ Lost": {
    background: "#FFCF76",
    color: "#030303",
  },
  Nurturing: {
    background: "#BCFB6C",
    color: "#484848",
  },
  Regret: {
    background: "#FFA4A4",
    color: "#202020",
  },
  Rejected: {
    background: "#FFA4A4",
    color: "#202020",
  },
  "RFQ Inspection": {
    background: "#FF6262",
    color: "#F6F6F6",
  },
  RFAI: {
    background: "#FF8359",
    color: "#FFFFFF",
  },
  "RFQ Verified": {
    background: "#FFCB98",
    color: "#484848",
  },
  "Vendor Scouting": {
    background: "#FFDDB7",
    color: "#484848",
  },
  "Shared to Vendor": {
    background: "#FFE6CE",
    color: "#484848",
  },
  "Price Submission": {
    background: "#FFF0D9",
    color: "#484848",
  },
  "Price Negotiation": {
    background: "#FBFFE5",
    color: "#484848",
  },
  "Price Negotiation (Re-Quote)": {
    background: "#FFF8C3",
    color: "#484848",
  },
  "Submission to OPS": {
    background: "#A6F343",
    color: "#393939",
  },
  NQS: {
    background: "#FF6767",
    color: "#F3F3F3",
  },
  "Margin Profile": {
    background: "#FF8359",
    color: "#FFFFFF",
  },
  "Price Nego (Sales)": {
    background: "#FFF0D9",
    color: "#484848",
  },
  "Quote Generation": {
    background: "#FBFFE5",
    color: "#484848",
  },
  "Quote Review": {
    background: "#E6FFAA",
    color: "#484848",
  },
  "Quote Submitted": {
    background: "#A6F343",
    color: "#393939",
  },
  "Request For Samples": {
    background: "#FF6767",
    color: "#F3F3F3",
  },
  Assigned: {
    background: "#FF8359",
    color: "#FFFFFF",
  },
  Communicated: {
    background: "#FFF0D9",
    color: "#484848",
  },
  "In Progress": {
    background: "#FBFFE5",
    color: "#484848",
  },
  Dispatched: {
    background: "#BCFB6C",
    color: "#000000",
  },
  Delivered: {
    background: "#70E77C",
    color: "#000000",
  },
  "PO Checked": {
    background: "#FF6262",
    color: "#FFFFFF",
  },
  "PO Generated": {
    background: "#FFB48A",
    color: "#FFFFFF",
  },
  "SO Estimated with dates": {
    background: "#FFCB98",
    color: "#000000",
  },
  "SO Shared": {
    background: "#FFDDB7",
    color: "#484848",
  },
  "Artwork Approval Client": {
    background: "#FFF0D9",
    color: "#484848",
  },
  "Artwork Revision": {
    background: "#FBFFE5",
    color: "#484848",
  },
  "Artwork Re-approval Client": {
    background: "#FFF8C3",
    color: "#484848",
  },
  "Artwork Approved": {
    background: "#E6FFAA",
    color: "#484848",
  },
  "PO Received": {
    background: "#FF8359",
    color: "#000000",
  },
  "SKU Checked and Vetted": {
    background: "#FFF0D9",
    color: "#000000",
  },
  "Estimated Date Confirmation": {
    background: "#FBFFE5",
    color: "#000000",
  },
  "SO Generation": {
    background: "#E6FFAA",
    color: "#000000",
  },
  "PO Generation": {
    background: "#A6F343",
    color: "#000000",
  },
  "PO Created": {
    background: "#FF6262",
    color: "#FFFFFF",
  },
  "PO Shared": {
    background: "#FF8359",
    color: "#FFFFFF",
  },
  "Artwork Verification": {
    background: "#FFB48A",
    color: "#000000",
  },
  "Artwork Verified": {
    background: "#FFCB98",
    color: "#000000",
  },
  "Production Planned": {
    background: "#FFDDB7",
    color: "#000000",
  },
  "Production Started": {
    background: "#FFE6CE",
    color: "#000000",
  },
  "Under Production (25%)": {
    background: "#FFF0D9",
    color: "#000000",
  },
  "Under Production (50%)": {
    background: "#FFF0D9",
    color: "#000000",
  },
  "Under Production (75%)": {
    background: "#FFF8C3",
    color: "#000000",
  },
  "Ready for Dispatch": {
    background: "#E6FFAA",
    color: "#000000",
  },
  "Partially Delivered": {
    background: "#70E77C",
    color: "#000000",
  },
  Closed: {
    background: "#00970F",
    color: "#FFFFFF",
  },
  "Artwork Generation": {
    background: "#FF8359",
    color: "#000000",
  },
  "Artwork Internal Check": {
    background: "#FFCB98",
    color: "#000000",
  },
  "Artwork Approval": {
    background: "#FFDDB7",
    color: "#000000",
  },
  "Artwork Revision Vendor": {
    background: "#FFF0D9",
    color: "#000000",
  },
  "Artwork Re-approval": {
    background: "#FFDDB7",
    color: "#000000",
  },
};
export interface CSVRow {
  [key: string]: string;
}

export const DealValueStages = [
  "Offer Generated",
  "Offer Submitted",
  "Re-Quote",
  "Re-Quoted",
  "Discussion & Negotiation",
  "Samples",
  "Re-Sample",
  "Sample Decision",
  "RFQ Won",
  "RFQ Lost",
  "Nurturing",
  "Regret",
];
