import React, { useEffect, useState } from "react";
import "./SkuDetail.scss";
import { useNavigate, useParams } from "react-router-dom";
import URLS from "../../urls/Urls";
import ApiService from "../../services/apiService";
import { SkuDetailSchema } from "../../models/SkuListSchema";
import SkuDetailCard from "../../components/SkuDetailCard/SkuDetailCard";
import SkuQualityCheck from "../../components/SkuQualityCheck/SkuQualityCheck";
import SideNav from "../../components/shared/sidenav/SideNav";
import SkuPricingTab from "../../components/SkuPricingTab/SkuPricingTab";
import XetModal from "../../components/shared/xet-modal/XetModal";
import OutsideAlerter from "../../utils/OutsideClickDetector";
import AddVendorDropdown from "../../components/AddVendorSkuPricingPopup/AddVendorSkuPricingPopup";

const SkuDetail: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(0);
  const [addNewQuality, setAddNewQuality] = useState(false);
  const [openAddVendorBox, setOpenAddVendorBox] = useState(false);
  const [addNewQualityTest, setAddNewQualityTest] = useState(false);
  const [skuDetail, setSkuDetail] = useState<SkuDetailSchema>();
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    getSkuDetails();
  }, []);

  const getSkuDetails = async () => {
    try {
      const { data } = await ApiService().client.get(
        URLS.SKU.GET_SKU_DETAIL(Number(id)),
      );
      setSkuDetail(data.data);
    } catch (error) {
      console.error("error fetching Sku Detail", error);
    }
  };

  const handleSkuDetailsUpdate = (data: SkuDetailSchema) => {
    setSkuDetail((prevState) => {
      if (!prevState) {
        return data;
      }

      return {
        ...prevState,
        name: data.name || prevState.name,
        description: data.description || prevState.description,
        production_remarks:
          data.production_remarks || prevState.production_remarks,
        sku_locations: data.sku_locations,
      };
    });
  };

  const handleOnAddVendors = () => {
    setRefresh((val) => !val);
    setOpenAddVendorBox(false);
  };

  return (
    <div className=" flex-row full-width">
      <SideNav></SideNav>
      <div className="sku-detail-main-page flex-column flex-1">
        <div className="upper-bar">
          <p
            className="xetgo-font-tag bold px-16 py-12 flex-row gap-4 cursor-pointer"
            onClick={() => navigate("/sku")}
          >
            SKU
            <span className="slash">/</span>
            <span className="sub-title xetgo-font-tag">
              {`${skuDetail?.name}`}
            </span>
          </p>
        </div>
        <div className="sku-detail-Lower-main-container flex-column gap-12 p-16 flex-1">
          <div className="sku-details-tab-main-container flex-row justify-content-space-between xetgo-font-tag">
            <div className="all-tab-container flex-row align-items-center p-2">
              <p
                onClick={() => setActiveTab(0)}
                className={`px-12 py-6 cursor-pointer bold ${
                  activeTab === 0 && "active"
                } `}
              >
                Details
              </p>
              <p
                onClick={() => setActiveTab(1)}
                className={`px-12 py-6 cursor-pointer bold ${
                  activeTab === 1 && "active"
                } `}
              >
                Quality Check
              </p>
              <p
                onClick={() => setActiveTab(2)}
                className={`px-12 py-6 cursor-pointer bold ${
                  activeTab === 2 && "active"
                } `}
              >
                Pricing
              </p>
            </div>
            {activeTab === 1 && (
              <div className="flex-row align-items-center gap-8">
                <div
                  onClick={() => setAddNewQuality(true)}
                  className="quality-add-btn flex-row align-items-center justify-content-center gap-8 px-12 py-8 cursor-pointer"
                >
                  <img
                    height={12}
                    width={12}
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231020043732-add.svg"
                    alt="add-icon"
                  />
                  <p>Add Property</p>
                </div>
              </div>
            )}
            {activeTab === 2 && (
              <div className="flex-row align-items-center gap-8">
                <div
                  onClick={() => setOpenAddVendorBox(true)}
                  className="quality-add-btn flex-row align-items-center justify-content-center gap-8 px-12 py-8 cursor-pointer"
                >
                  <img
                    height={12}
                    width={12}
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231020043732-add.svg"
                    alt="add-icon"
                  />
                  <p>Add Vendor</p>
                </div>
              </div>
            )}
          </div>
          <div className="flex-1 tab-details-container">
            {activeTab === 0 && skuDetail && (
              <SkuDetailCard
                sku={skuDetail}
                handleUpdate={handleSkuDetailsUpdate}
              />
            )}
            {activeTab === 1 && skuDetail && (
              <SkuQualityCheck
                addNewQuality={addNewQuality}
                setAddNewQuality={setAddNewQuality}
                skuId={skuDetail.id}
                addNewQualityTest={addNewQualityTest}
                setAddNewQualityTest={setAddNewQualityTest}
              />
            )}
            {activeTab === 2 && skuDetail && (
              <SkuPricingTab sku={skuDetail} refresh={refresh} />
            )}
            {skuDetail && (
              <XetModal
                backdropClose={true}
                open={openAddVendorBox}
                handleClose={() => setOpenAddVendorBox(false)}
              >
                <OutsideAlerter action={() => setOpenAddVendorBox(false)}>
                  <AddVendorDropdown
                    skuId={skuDetail.id}
                    addresses={skuDetail.sku_locations}
                    onClose={() => setOpenAddVendorBox(false)}
                    onAddVendors={handleOnAddVendors}
                  />
                </OutsideAlerter>
              </XetModal>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkuDetail;
