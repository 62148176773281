import React, { useEffect, useState } from "react";
import { FilterTypes } from "./Filter.data";
import "./Filter.scss";
import FilterDropdown from "../FilterDropdown/FilterDropdown";
import OutsideAlerter from "../../../../utils/OutsideClickDetector";
import { FilterOptionSchema } from "../../../../redux/oms/omsTypes";
import { SearchTypes } from "./SearchTypes.data";

interface IFilterPayload {
  option: FilterTypes | SearchTypes;
  optionList: FilterOptionSchema[];
  multipleSelection: boolean;
  onSelection: (
    val: FilterOptionSchema[],
    option: FilterTypes | SearchTypes,
  ) => void;
  mode?: "showCount" | "showNames";
  showPlaceholder?: boolean;
  borderStyle?: "dashed" | "solid";
  preSelected?: FilterOptionSchema[];
  direction?: "left" | "right";
  addNew?: boolean;
  onAddNew?: () => void;
  showId?: boolean;
  classname?: string;
}

const Filter = ({
  option,
  optionList,
  multipleSelection,
  onSelection,
  mode = "showCount",
  showPlaceholder = true,
  borderStyle = "dashed",
  preSelected = [],
  direction = "right",
  addNew = false,
  onAddNew = () => {},
  showId = false,
  classname = "",
}: IFilterPayload) => {
  const [selected, setSelected] = useState<FilterOptionSchema[]>([]);
  const [show, setShow] = useState(false);
  const filterToggle = () => {
    setShow((show) => !show);
  };

  const filterSelected = (val: FilterOptionSchema[]) => {
    setSelected(val);
    onSelection(val, option);
    if (!multipleSelection) {
      filterToggle();
    }
  };

  const addNewHandler = () => {
    setShow(false);
    onAddNew();
  };

  useEffect(() => {
    if (JSON.stringify(selected) !== JSON.stringify(preSelected)) {
      setSelected(preSelected);
    }
  }, [preSelected]);

  return (
    <div className={`filter-main-container `}>
      <OutsideAlerter action={() => setShow(false)}>
        <div
          className={`filter-container ${
            borderStyle === "solid" ? "solidBorder" : "dashedBorder"
          }  ${
            show || selected.length > 0 ? "active" : "inactive"
          } ${classname}`}
          onClick={filterToggle}
        >
          <p
            className={`title flex-row gap-8 m-0 noselect ${
              show || selected.length > 0
                ? "xetgo-font-tag-bold"
                : "xetgo-font-tag"
            }`}
          >
            {showPlaceholder ? option.name : ""}

            {selected.length > 0 && mode === "showCount" && (
              <span className="flex-row align-items-center justify-content-center xetgo-font-tag-bold filter-count">
                {selected.length}
              </span>
            )}

            {selected.length > 0 && mode === "showNames" && (
              <span className="flex-row align-items-center justify-content-center xetgo-font-tag-bold">
                {selected
                  .map((selectedOption) => {
                    return selectedOption.name;
                  })
                  .join(", ")}
              </span>
            )}
          </p>
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231005115527-chevron-down.svg"
            alt="dropdown-arrow"
            height={16}
            width={16}
          />
        </div>
        {show && (
          <FilterDropdown
            filter={option}
            options={optionList}
            multipleSelection={multipleSelection}
            selected={selected}
            selection={filterSelected}
            direction={direction}
            addNew={addNew}
            addNewClicked={addNewHandler}
            showId={showId}
          />
        )}
      </OutsideAlerter>
    </div>
  );
};

export default Filter;
